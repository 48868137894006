import { Call, LocationOnOutlined, Mail } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css"

export const Footer = () => {
  return (
    <div>
      <div className=" row m-0 w-100">
        <div className=" text-center  ps-4  pt-2 col-md-4 col-sm-6 col-12">
          {" "}
          <img
            src={require("../../../Images/logo 1.png")}
            height={60}
            width={200}
            alt=""
          />
          {/* <h4 className="">About</h4> */}
          <p className=" fw-semibold mt-2 ">
            Sahara Demolition stands as a foremost demolition firm committed to
            delivering secure and proficient demolition solutions for
            residential, commercial, and industrial assets.
          </p>
        </div>

        <div className=" text-center pt-4  col-md-2 col-sm-6 col-12">
          {" "}
          <h4>Quick Links</h4>
          {/* <div className="mt-4"><LocationOnOutlined /></div> */}
          <div className="mt-2">
            <p className=" p-1 m-0 fw-semibold">
              {" "}
              <Link to="/" style={{ color: "black", textDecoration: "none" }}>
                {" "}
                Home
              </Link>
            </p>
            <p className=" p-1 m-0 fw-semibold">
              {" "}
              <Link
                to="Services"
                style={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                Services
              </Link>
            </p>
            <p className=" p-1 m-0 fw-semibold">
              {" "}
              <Link
                to="/AboutUs"
                style={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                About Us
              </Link>
            </p>
            <p className=" p-1 m-0 fw-semibold">
              {" "}
              <Link
                to="/ContactUs"
                style={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                Contact Us
              </Link>
            </p>
          </div>
        </div>

        <div className=" text-center pt-4  col-md-3 col-sm-6 col-12">
          {" "}
          <h4>Our Services</h4>
          {/* <div className="mt-4"><LocationOnOutlined /></div> */}
          <div className="mt-2">
            <p className=" p-1 m-0 fw-semibold">
              {" "}
              <Link
                to="/Services"
                style={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                High Reach Demolition
              </Link>
            </p>
            <p className=" p-1 m-0 fw-semibold">
              {" "}
              <Link
                to="/Services"
                style={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                Multi-Story Demolition
              </Link>
            </p>
            <p className=" p-1 m-0 fw-semibold">
              {" "}
              <Link
                to="/Services"
                style={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                Commercial Demolition
              </Link>
            </p>
            <p className=" p-1 m-0 fw-semibold">
              {" "}
              <Link
                to="/Services"
                style={{ color: "black", textDecoration: "none" }}
              >
                {" "}
                Industrial Demolition
              </Link>
            </p>
          </div>
        </div>

        <div className="  text-center pt-4  col-md-3 col-sm-6 col-12">
          {" "}
          <h4>Contact</h4>
          <div className="mt-4">{/* <LocationOnOutlined /> */}</div>
          <div className="mt-4">
            <Call />{" "}
            <a
              className=" text-dark fw-semibold"
              style={{ textDecoration: "none" }}
              href="tel:+718-233-6300"
            >
              +718-233-6300
            </a>
          </div>
          <div className="mt-4">
            <Mail />{" "}
            <a
              className=" text-dark fw-semibold"
              style={{ textDecoration: "none" }}
              href="mailto:admin@saharacontractingco.com"
            >
              
              <span>estimating@sahara</span>{" "}
              <span style={{ marginLeft: "-4px" }}>contractingco.com</span>
            </a>
          </div>
        </div>
      </div>
      <div>
        <p  className=" text-center m-0 p-0 copyright">
          Copyright © 2024 Sahara Demolition Designed By{" "}
          <a href="https://mfbzone.com/" target="blank">
            {" "}
            <span style={{ color: "#008000", fontWeight: "700" }}> MF</span>
            <span style={{ color: "#FF6400", fontWeight: "700" }}>Bzone</span>
          </a>
        </p>
      </div>
    </div>
  );
};
