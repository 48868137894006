import React, { useContext } from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
// import Form from 'react-bootstrap/Form';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, NavLink } from "react-router-dom";
// import AcUnitIcon from '@mui/icons-material/AcUnit';
import {
  Call,
  FacebookOutlined,
  Instagram,
  LinkedIn,
  Mail,
} from "@mui/icons-material";
import { MyContext } from "../../../Store/Context";
export const Header = () => {
  const contextvalues = useContext(MyContext);
  const openForm = () => {
    contextvalues.ChangeFormEvent();
  };
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          fixed="top"
          className="bg-body-tertiary mb-3 navbar p-0"
        >
          <Container fluid>
            <Navbar.Brand className=" ms-5" href="#">
              <img
                src={require("../../../Images/logo 1.png")}
                className=" brandLogo"
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center align-items-center flex-grow-1 pe-3">
                  <Nav.Link>
                    <NavLink className={"RedirectLink"} to="/">
                      Home
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link>
                    <NavLink className={"RedirectLink"} to="Services">
                      Services
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link>
                    <NavLink className={"RedirectLink"} to="AboutUs">
                      About Us
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link>
                    <NavLink className={"RedirectLink"} to="ContactUs">
                      Contact Us
                    </NavLink>
                  </Nav.Link>
                </Nav>

                <Nav className="justify-content-end align-items-center flex-grow-1 pe-3">
                  <Nav.Link style={{ color: "black" }} href="#action1">
                    <Instagram></Instagram>
                  </Nav.Link>
                  <Nav.Link style={{ color: "black" }} href="#action2">
                    {" "}
                    <FacebookOutlined />
                  </Nav.Link>
                  <Nav.Link style={{ color: "black" }} href="#action2">
                    {" "}
                    <LinkedIn />
                  </Nav.Link>
                  <Nav.Link style={{ color: "black" }} href="#action2">
                    {" "}
                    <a
                      className=" "
                      style={{ textDecoration: "none", color: "black" }}
                      href="mailto:office.saharacontracting@gmail.com"
                    >
                      {" "}
                      <Mail />
                    </a>{" "}
                  </Nav.Link>
                  <Nav.Link style={{ color: "black" }} href="#action2">
                    {" "}
                    <a
                      className=""
                      style={{ textDecoration: "none", color: "black" }}
                      href="tel:+1 838-334-6728"
                    >
                      {" "}
                      <Call />{" "}
                    </a>{" "}
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};
