import React, { useContext } from "react";
import "./Home.css";
import { Banner } from "../CommonComponents/Banner";
import { Banner2 } from "../CommonComponents/Banner2";
import { MyContext } from "../../Store/Context";
export const Home = () => {
  const contextvalues = useContext(MyContext);
  const openForm = () => {
    contextvalues.ChangeFormEvent();
  };
  return (
    <div className=" w-100 ">
      <div className=" row  w-100 m-0 mt-5">
        <div
          className="Hero-Section-Content-Container col-md-7"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className=" m-0 p-0">
            <h1 className="">
              {/* Bringing Buildings to Their Knees:Your Demolition Experts{" "} */}
              Making Way for the Future: Expert Demolition Services.
            </h1>
            <p className=" fw-semibold">
              Budgeting for Demolition? Sahara Reveals the Real Costs for a
              Successful Project.
            </p>

            <button onClick={openForm} className=" btn btn-dark custom_style">
              Contact us
            </button>
          </div>
        </div>
        <div
          className=" col-md-5  m-0 p-0"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../Images/Rectangle 4.png")}
            className="hero_img"
            alt=""
          />
        </div>
      </div>
      <div className=" pt-5 pb-5" style={{ backgroundColor: "#1E1E1E" }}>
        <div className=" container-lg">
          <div className="About_Company">
            <h3>About our company</h3>
            <p className=" m-0 p-0 fw-semibold">
              {/* Sahara Demolition stands as a foremost demolition firm committed
              to delivering secure and proficient demolition solutions for
              residential, commercial, and industrial assets. Armed with a
              competent team of professionals and cutting-edge machinery, we
              excel in tearing down structures of every magnitude, from
              miniature buildings to expansive industrial units. Our stringent
              adherence to superior standards and meticulous attention to
              specifics ensure every demolition task is accomplished with
              unparalleled accuracy and safeguarding. */}
              <strong>Safe, Expert Demo for Every Project</strong> <br />
              At Sahara Demolition, we're passionate about tackling your
              demolition challenges with precision and safety. Our highly
              skilled crew and state-of-the-art equipment can handle any
              project, big or small–from cozy bungalows to sprawling industrial
              facilities. We prioritize clear communication and thorough
              planning to ensure every demolition is completed with accuracy and
              the utmost safety.
            </p>
          </div>

          <div className=" services-section">
            <h3 className="Services-Heading text-center"> Services Section</h3>
            <p className=" text-center">
              We service projects of all sizes including
            </p>
            <div className=" row w-100 m-0  justify-content-center">
              <div
                className="col-md-4 col-sm-6 col-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="RESIDENTIAL">
                  <h3 className="text-center">RESIDENTIAL</h3>
                  <p className="text-center mt-4">
                    Our home demolition services encompass gutting of kitchens
                    and bathrooms, roof demolition, destroying garages and
                    chimneys, siding elimination, and complete interior house
                    clearing.
                  </p>
                </div>
              </div>

              <div
                className="col-md-4 col-sm-6 col-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="COMMERCIAL p-3">
                  <h3 className="text-center">COMMERCIAL</h3>
                  <p className="text-center mt-4">
                    We have executed a variety of commercial demolition
                    assignments. More than 50 shopping centers across New
                    England have seen our work, typically implemented during
                    nocturnal hours.
                  </p>
                </div>
              </div>

              <div
                className="col-md-4 col-sm-6 col-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="MECHANICAL">
                  <h3 className="text-center">INDUSTRIAL</h3>
                  <p className="text-center mt-4">
                    At Sahara Demolition, we're the muscle behind the makeover.
                    We specialize in safe and efficient demolition of
                    large-scale industrial structures. Our experienced crew
                    handles everything, from dismantling buildings to removing
                    them completely, so you can focus on building your future.
                  </p>
                </div>
              </div>
            </div>

            <div className=" mt-5">
              <Banner2 />
            </div>
            <div className=" text-center m-5">
              {/* <button onClick={openForm} class=" ContactBtn">
                Contact Us
              </button> */}
            </div>
            <div className=" mt-5">
              <div className=" row w-100 m-0  justify-content-center">
                <div
                  className="col-md-6 col-12 m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className=" p-4 The_Process m-0">
                    <h3 className="text-center">The Process</h3>
                    <p className="text-center mt-4">
                      The act of structural demolition includes the physical
                      deconstruction of any form of building, whether it's a
                      home, a business establishment, a barn, or a garage. This
                      procedure can be implemented either by employing potent
                      machinery for conventional mechanical demolition.
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6 col-12 m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className=" p-3 m-0">
                    <h3 className="text-center">
                      Importance of Hiring the Right Person
                    </h3>
                    <p className="text-center mt-4">
                      Hiring the best team for the project is one of the most
                      critical components of structural demolition. It entails
                      locating a partner contractor with the knowledge and
                      skills to safely and quickly complete the task. It is
                      critical not just for the project’s success but also for
                      the safety of everyone engaged.
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6 col-12 m-0 p-0"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <div className=" p-3 m-0">
                    <h3 className="text-center">Expertise and Safety</h3>
                    <p className="text-center mt-4">
                      The hazards of demolition work are indeed severe and
                      simply cannot be exaggerated. Daily, demolition workers
                      are exposed to potential risks such as falls,
                      electrocutions, and contact with harmful substances.
                    </p>
                  </div>
                </div>

                <div
                  className="col-md-6 col-12  p-0"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-2px",
                    marginLeft: "-3px",
                  }}
                >
                  <div className="p-3 Cost_Risk m-0">
                    <h3 className="text-center">The High Cost of Risk</h3>
                    <p className="text-center mt-4">
                      Inexperienced contractors often cut corners, putting
                      safety at risk. This can lead to accidents, injuries, or
                      even worse. They might also damage nearby buildings,
                      causing even more headaches. Play it safe. Choose Sahara
                      Demolition. We're experts in bringing down structures of
                      all sizes, the right way. Our team uses the latest
                      equipment to ensure a safe and efficient demolition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-5">
            <Banner />
          </div>

          <div className=" mt-5">
            <h2 className=" text-center text-light">
              <u>Projects</u>
            </h2>
            <div className="row w-100 m-0 justify-content-center mt-4">
              <div className=" col-md-6 col-12">
                <div className="p-3 m-0 text-light">
                  <h3 className="">Project </h3>
                  <p className=" mt-4">
                    The Sahara Demolition project involves the controlled
                    dismantling and removal of structures and infrastructure in
                    the Sahara Desert. It aims to clear the area for potential
                    development or restoration projects, addressing
                    environmental concerns and promoting sustainability in the
                    region.
                  </p>
                </div>
              </div>
              <div className=" col-md-3 col-12 projectImgContainer">
                <p className=" text-center">
                  {" "}
                  <img src={require("../../Images/Project.png")} alt="" />
                </p>
              </div>
              <div className=" col-md-3 col-12 projectImgContainer ">
                {" "}
                <p className=" text-center">
                  {" "}
                  <img src={require("../../Images/Project2.png")} alt="" />
                </p>
              </div>
            </div>
          </div>

          <div className=" text-center m-5">
            <button onClick={openForm} class="ContactBtn">
              <span class="text-container">
                <span class="text">Contact Us</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
