import CardPic1 from "../../Images/CardPic1.png";
import CardPic2 from "../../Images/CardPic2.png";
import CardPic3 from "../../Images/CardPic3.png";
import CardPic4 from "../../Images/CardPic4.png";
import CardPic5 from "../../Images/CardPic5.png";
import CardPic6 from "../../Images/CardPic6.png";
import CardPic7 from "../../Images/CardPic7.png";
import CardPic8 from "../../Images/CardPic8.png";
import CardPic9 from "../../Images/CardPic9.png";
import './Services.css'
import Slider from "react-slick";
import { ServicesCard } from "../CommonComponents/ServicesCard";
import { Banner } from "../CommonComponents/Banner";
export const Services = () => {
  const CardArray = [
    {
      Title: "Demolition",
      Description:
        "From bungalows to factories, Sahara Demo tackles any demolition project in your area. ",
      Image: CardPic1,
    },
    {
      Title: "Site Cleanouts",
      Description:
        "Our dedicated team at Sahara Demolition specializes in thorough site cleanouts.",
      Image: CardPic2,
    },
    {
      Title: "Rubbish Removal",
      Description:
        "At Sahara Demolition, we offer wide-ranging rubbish removal services.",
      Image: CardPic3,
    },
    {
      Title: "High Reach Demolition",
      Description:
        "We specialize in high reach demolition, utilizing advanced equipment",
      Image: CardPic4,
    },
    {
      Title: "Multi-Story Demolition",
      Description:
        "Multi-Story Demolition Made Easy. Let Sahara Demo Be Your Partner.",
      Image: CardPic5,
    },
    {
      Title: "Commercial Demolition",
      Description:
        "Sahara Demo specializes in commercial demolition, offering comprehensive services .",
      Image: CardPic6,
    },
    {
      Title: "Industrial Demolition",
      Description:
        "Sahara Demolition is well-equipped to handle industrial demolition projects",
      Image: CardPic7,
    },
    {
      Title: "Chimney Demolition",
      Description:
        "Sahara Demolition specializes in chimney demolition, providing expert services efficiently",
      Image: CardPic8,
    },
    {
      Title: "Selective Demolition",
      Description:
        "Sahara Demolition excels in selective demolition, offering precise and strategic dismantling services",
      Image: CardPic9,
    },
  ];
  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         display: "block",
  //         background: "black",
  //         color: "red",
  //         borderRadius: "50%",
  //         padding:"01px 0px",
         
  //       }}
  //       onClick={onClick}
  //     />
  //   );
  // }

  const settings = {
    dots: true,
    // arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    // nextArrow: <SamplePrevArrow />,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1000, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 2, // Number of items to show at this breakpoint
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of items to show at this breakpoint
          slidesToScroll: 1,
        },
      },
      
    ]
  };
  return (
    <div  style={{ backgroundColor: "", paddingTop:"30px" }}>
      <div className="  container mt-5 p-2">
        

        <Slider {...settings}>
        {CardArray.map((item) => (
          // <div style={{display:"flex", justifyContent:"center"}}>
          <ServicesCard
            Title={item.Title}
            Description={item.Description}
            Image={item.Image}
          />
         // {/* </div> */}
        ))}
        </Slider>

       <div style={{border:"12px solid", borderRadius:"40px", padding: "10px" }} className=" mt-5">
       <Banner/>
       </div>
      </div>
    </div>
  );
};
