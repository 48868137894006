import React, { useContext } from "react";
import "./CommonStyle.css";
import { MyContext } from "../../Store/Context";
export const Banner2 = () => {
  const contextvalues = useContext(MyContext);
  const openForm = () => {
    contextvalues.ChangeFormEvent();
  };
  return (
    <div>
      <div className="banner_container2">
        <div className="banner-Content-Container2">
          <h3 className=" fw-bold"> Call us now </h3>
          <p className=" fw-semibold">
            Contact us on{" "}
            <a
              className=" phoneNumber"
              style={{ textDecoration: "none" }}
              href="tel:+718-233-6300"
            >
              +718-233-6300
            </a>
          </p>
          <button onClick={openForm} className="btn btn-dark custom_style">
            Contact Now
          </button>
        </div>
      </div>
    </div>
  );
};
