import React from "react";
import "./AboutUs.css";
import { Banner2 } from "../CommonComponents/Banner2";
// import icon from "../../Images/ICON.svg";
export const AboutUs = () => {
  return (
    <div style={{ backgroundColor: "#1E1E1E" }}>
      <div className=" container">
        <div style={{ paddingTop: "120px" }} className=" ">
          <h1 className=" aboutUs text-uppercase text-light">About Us </h1>
        </div>
        <div className="Hero-Section-Container">
          <div className="item">
            <p>
              {/* Sahara Demolition is a premier demolition firm committed to
              delivering reliable and effective demolition services for
              residential, commercial, and industrial properties. We have a crew
              of highly competent experts and advanced machinery, specializing
              in bringing down structures of varying scales, from minor
              buildings to extensive industrial facilities. Our dedication to
              exceptional performance and meticulous attention to detail
              guarantees every demolition task is accomplished with optimal
              accuracy and security. Regardless if you need selective
              demolition, all-encompassing building demolition, or site
              clearance services, Sahara Wrecking is your dependable ally. We
              take pride in achieving outstanding outcomes while keeping a
              rigorous focus on promoting environmental sustainability and
              practicing responsible waste disposal methods. Opt for Sahara
              Wrecking for all your wrecking needs and enjoy supreme
              professionalism and proficiency in the field. */}
              At Sahara Demo, our highly skilled crew and latest equipment can
              tackle any demolition project, big or small, from cozy bungalows
              to sprawling industrial complexes. We specialize in: <br /> <br />
              <ul>
                <li>
                  {" "}
                  Selective Demolition: Need to take down just a part of a
                  building? We've got you covered.{" "}
                </li>
                <li>
                  {" "}
                  Complete Building Demolition: Time to clear a lot for your
                  next big project? We'll handle it safely and efficiently.{" "}
                </li>
                <li>
                  {" "}
                  Site Clearing Services: Ready to build from the ground up? Let
                  us prepare your site.{" "}
                </li>
              </ul>
              Furthermore, we're passionate about protecting the environment. We
              use responsible waste disposal methods and focus on sustainable
              practices whenever possible.
            </p>
          </div>
          <div className=" item">
            <img src={require("../../Images/Aboutus.png")} alt="" />
          </div>
        </div>

        <div className=" services-section">
          <h3 className="Services-Heading text-center"> Services Section</h3>
          <p className=" text-center">
            We service projects of all sizes including
          </p>
          <div className=" row w-100 m-0  justify-content-center">
            <div
              className="col-md-4 col-sm-6 col-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="RESIDENTIAL">
                <h3 className="text-center">RESIDENTIAL</h3>
                <p className="text-center mt-4">
                  Our home demolition services encompass gutting of kitchens and
                  bathrooms, roof demolition, destroying garages and chimneys,
                  siding elimination, and complete interior house clearing.
                </p>
              </div>
            </div>

            <div
              className="col-md-4 col-sm-6 col-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="COMMERCIAL p-3">
                <h3 className="text-center">COMMERCIAL</h3>
                <p className="text-center mt-4">
                  We have executed a variety of commercial demolition
                  assignments. More than 50 shopping centers across New England
                  have seen our work, typically implemented during nocturnal
                  hours.
                </p>
              </div>
            </div>

            <div
              className="col-md-4 col-sm-6 col-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="MECHANICAL">
                <h3 className="text-center">INDUSTRIAL</h3>
                <p className="text-center mt-4">
                  At Sahara Demolition, we're the muscle behind the makeover. We
                  specialize in safe and efficient demolition of large-scale
                  industrial structures. Our experienced crew handles
                  everything, from dismantling buildings to removing them
                  completely, so you can focus on building your future.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-5 pb-5">
          <Banner2 />
        </div>
      </div>
    </div>
  );
};
