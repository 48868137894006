import React, { useContext } from "react";
import { MyContext } from "../../Store/Context";

export const ServicesCard = (props) => {
  const changeValue = useContext(MyContext);
  
  const Open_Form = ()=>{
       changeValue.ChangeFormEvent();
  };
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
      <div className="Card_Container">
        <div className="Img_Container">
          <img src={props.Image} width={"100%"} alt="" />
        </div>
        <h4>{props.Title}</h4>
        <p>
          {" "}
          {props.Description}
        </p>
        <button onClick={Open_Form}  class="btn-18 ">
          <span class="text-container">
            <span class="text">Get Our Services</span>
          </span>
        </button>
      </div>
    </div>
  );
};
