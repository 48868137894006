import React, { useContext, useState } from "react";
import "./CommonStyle.css";
import { MyContext } from "../../Store/Context";
export const ContactForm = () => {
  const handleAttachmentChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  // const [disAble, setdisAble] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Contact, setContect] = useState("");
  const [filelink, setfilelink] = useState("");
  const [Requirements, setRequirements] = useState("");
  const [attachment, setAttachment] = useState(null);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("Contact", Contact);
    formData.append("attachment", attachment);
    formData.append("link", filelink);
    formData.append("Requirements", Requirements);

    try {
      const response = await fetch(
        "https://saharademolition.000webhostapp.com/",
        {
          method: "POST",
          body: formData,
          // mode: 'no-cors',
        }
      );

      if (response.status === 200) {
        alert("Email sent successfully");
      } else {
        alert("Email sending failed");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Email sending failed");
    }
    // setIsLoadingSubmit(false);
  };
  const changeValue = useContext(MyContext);

  const close_Form = () => {
    changeValue.ChangeFormEvent();
  };
  return (
    <div className="FormOuterContainer">
      <div className="FormContainer">
        <h3 className=" fw-bold text-center">Get in Touch with us </h3>
        <p className=" text-center">Let us know how we can help</p>
        <form onSubmit={onFormSubmit}>
          <input
            onChange={(e) => setName(e.target.value)}
            type="text"
            name=""
            placeholder="Enter your name"
            id=""
          />
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
            required
            name=""
            placeholder="Enter your Email *(Required)"
            id=""
          />{" "}
          <textarea
            onChange={(e) => setRequirements(e.target.value)}
            name="Message"
            id=""
            placeholder="Message"
            rows="3"
          ></textarea>
          <input
            onChange={handleAttachmentChange}
            type="file"
            name=""
            placeholder="Enter your file"
            id=""
          />
          <input type="submit" className="SubmitButten" value="Send Message" />{" "}
        </form>
        <button
          onClick={close_Form}
          className=" btn btn-close closeBtn"
        ></button>
      </div>
    </div>
  );
};
