import React, { useContext, useState } from "react";
import { MyContext } from "../../Store/Context";
import "./ContactUs.css";
export const ContactUs = () => {
  // const changeValue = useContext(MyContext);
  // const Open_Form = () => {
  //   changeValue.ChangeFormEvent();
  // };

  const handleAttachmentChange = (e) => {
    setAttachment(e.target.files[0]);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Contact, setContect] = useState("");
  const [filelink, setfilelink] = useState("");
  const [Requirements, setRequirements] = useState("");
  const [attachment, setAttachment] = useState(null);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("Contact", Contact);
    formData.append("attachment", attachment);
    formData.append("link", filelink);
    formData.append("Requirements", Requirements);

    try {
      const response = await fetch(
        "https://saharademolition.000webhostapp.com/",
        {
          method: "POST",
          body: formData,
          // mode: 'no-cors',
        }
      );

      if (response.status === 200) {
        alert("Email sent successfully");
      } else {
        alert("Email sending failed");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Email sending failed");
    }
    // setIsLoadingSubmit(false);
  };
  return (
    <div className=" p-md-5 mt-4 " style={{ backgroundColor: "#1E1E1E" }}>
      <div className=" container-xl">
        <h1 style={{ color: "white" }} className="  mt-5 mb-3">
          Contact Us
        </h1>
        <div className=" ContactFormContainer">
          <div className="ContactUsForm">
            <h3 className=" fw-bold text-center">Get in Touch with us </h3>
            <p className=" text-center">Let us know how we can help</p>
            <form onSubmit={onFormSubmit}>
              <input
                onChange={(e) => setName(e.target.value)}
                type="text"
                name=""
                placeholder="Enter your name"
                id=""
              />
              <input
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                name=""
                placeholder="Enter your Email  *(Required)"
                id=""
              />
              <textarea
                onChange={(e) => setRequirements(e.target.value)}
                name="Message"
                id=""
                placeholder="Message"
                rows="3"
              ></textarea>
              <input
                onChange={handleAttachmentChange}
                type="file"
                name=""
                placeholder="Enter your file"
                id=""
              />
              <input
                type="submit"
                className="Send-Message"
                value="Send Message"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
