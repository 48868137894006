import { Route, Routes } from "react-router-dom";
import "./App.css";
import { Header } from "./Components/CommonComponents/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
// import 'bootstrap/dist/css/bootstrap.css'
import { Home } from "./Components/Home/Home";
import { Services } from "./Components/Services/Services";
import { AboutUs } from "./Components/AboutUs/AboutUs";
import { ContactUs } from "./Components/ContactUs/ContactUs";
import { Footer } from "./Components/CommonComponents/Footer/Footer";
import { ContactForm } from "./Components/CommonComponents/ContactForm";
import { useState } from "react";
import { MyContext } from "./Store/Context";
import { ScrolltoTop } from "./ScrolltoTop/ScrolltoTop";

function App() {
  const [showContactForm, setShowContactForm] = useState(false);
  const changeEvent = () => {
    setShowContactForm(!showContactForm);
  };
  return (
    <MyContext.Provider  value={{value: showContactForm, ChangeFormEvent: changeEvent}}>

      {showContactForm ? <ContactForm /> : ""}
      <Header />
<ScrolltoTop/>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
      <Footer />
    </MyContext.Provider>
  );
}

export default App;
