import React, { useContext } from "react";
import "./CommonStyle.css";
import { MyContext } from "../../Store/Context";
export const Banner = () => {
  const contextvalues = useContext(MyContext);
  const openForm = () => {
    contextvalues.ChangeFormEvent();
  };
  return (
    <div>
      <div className="banner_container">
        <div className="banner-Content-Container">
          <h3 className=" fw-bold"> Get in Touch with us </h3>
          <p className=" fw-semibold">
            Demolition Done-Right Starts with Knowing the Cost: Get an Accurate
            Estimate from Sahara Demo!
          </p>
          <button
            onClick={openForm}
            className="btn btn-dark custom_style WalkThrough"
          >
            Schedule a Walk through
          </button>
        </div>
      </div>
    </div>
  );
};
